// @flow
import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import classnames from 'classnames';

import { paths } from '../../config';
import Layout from '../layouts/DefaultLayout';
import DefaultMeta from './DefaultMeta';
import SEO from './SEO';
import PostListItem from './PostListItem';
import Pagination from './Pagination';
import PageHeaderWithAddButton from './PageHeaderWithAddButton';
import TaxonomySelect from './TaxonomySelect';
import Modal from './Modal';
import { IntroText, Button } from '../styles';
import Link from './Link';
import ItemSubmissionForm from './ItemSubmissionForm';
import TextField from './TextField';
import Select from './Select';
import Collapse from './Collapse';
import FileUploadField from './FileUploadField';
import useSubmissionModal from '../hooks/useSubmissionModal';

const StyledImg = styled(Img)`
  width: auto;
  max-width: 582px;
`;

const StyledSelect = styled(Select)`
  max-width: 368px;
`;

const StyledModal = styled(Modal)`
  .h1 {
    text-align: center;
  }
`;

const ModalIntroText = styled(IntroText)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  &:hover,
  &:focus {
    color: #26985f;
    border-color: var(--color-border);
  }

  &.active {
    background: #26985f;
    color: #fff;
  }
`;

const SubmissionTypeToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${StyledButton}:first-child {
    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
    border-right-width: 1px;
  }

  ${StyledButton}:last-child {
    border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
    border-left-width: 1px;
  }
`;

export type Props = {
  pageContext: {
    basePath: string,
    title: string,
    introText: string,
    canonical: string,
    numPages: number,
    currentPage: number,
    tag: string,
    category: string,
  },
  data: {
    alternatives: {
      edges: Array<{
        node: {
          id: string,
          title: string,
          path: string,
          excerpt: string,
          type: string,
          featuredImage?: {
            localFile?: {
              childImageSharp: {
                fluid: any,
              },
            },
            alt: string,
          },
          tags?: Array<{
            path: string,
            name: string,
          }>,
          customMeta: {
            commentCount: number,
          },
        },
      }>,
    },
    allTags: {
      edges: Array<{
        node: {
          id: string,
          path: string,
          name: string,
        },
      }>,
    },
    allCategories: {
      edges: Array<{
        node: {
          id: string,
          path: string,
          name: string,
        },
      }>,
    },
  },
};

const Alternatives = (props: Props) => {
  const {
    pageContext: {
      basePath,
      title,
      introText,
      canonical,
      numPages,
      currentPage,
      tag: currentTagId,
      category: currentCatId,
    },
    data: {
      alternatives,
      allTags,
      allCategories,
    },
  } = props;

  const taxonomies = currentCatId ? allCategories : allTags;
  const currentTaxId = currentCatId || currentTagId || 'everything';

  const pageTitle = `${title} - ethical.net`;

  const [
    submissionModalOpen,
    submissionFiles,
    submissionModalImage,
    toggleSubmissionModal,
    handleSubmissionFileChange,
  ] = useSubmissionModal();
  const [submissionType, setSubmissionType] = useState<'brand' | 'guide' | null>(null);

  const productOptions = useMemo(
    () => allCategories.edges.map(({ node: { name } }) => ({ value: name, label: name })),
    [allCategories],
  );

  const handleBrandClick = useCallback(() => {
    setSubmissionType('brand');
  }, []);

  const handleGuideClick = useCallback(() => {
    setSubmissionType('guide');
  }, []);

  const brandFormFieldsMarkup = (
    <>
      <input type="hidden" name="_subject" value="New alternative brand submission!" />
      <TextField name="Name" label="Brand Name" required />
      <StyledSelect name="Products" label="Products" options={productOptions} isMulti allowCreate isSearchable />
      <TextField name="Website" label="Website URL" type="url" required />
      <TextField name="Description" label="Description" multiline minLength={280} required />
      <Collapse title="Optional: Add Mission Statement">
        <TextField name="Mission-Statement" label="Mission Statement" multiline minLength={280} />
      </Collapse>
    </>
  );

  const guideFormFieldsMarkup = (
    <>
      <input type="hidden" name="_subject" value="New guide submission!" />
      <TextField name="Title" label="Title" required />
      <TextField name="Products" label="Products or Services" required />
      <TextField name="Description" label="Description" multiline minLength={280} required />
    </>
  );

  return (
    <Layout>
      <DefaultMeta />
      <SEO
        title={pageTitle}
        canonical={canonical}
        meta={[{
          property: 'og:title',
          content: pageTitle,
        }, {
          property: 'og:url',
          content: canonical,
        }]}
      />

      <PageHeaderWithAddButton
        title={title}
        introText={introText}
        addBtnTextSuffix="an Alternative"
        onAddBtnClick={toggleSubmissionModal}
      />

      <TaxonomySelect
        taxonomies={taxonomies.edges.map(({ node }) => node)}
        currentTaxId={currentTaxId}
        basePath={paths.alternatives}
      />

      <div className="row">
        {alternatives.edges.map((edge) => (
          <div key={edge.node.id} className="col-sm-6 col-md-4 col-lg-3">
            <PostListItem item={edge.node} />
          </div>
        ))}
      </div>

      {numPages > 1 && (
        <Pagination
          basePath={basePath}
          numPages={numPages}
          currentPage={currentPage}
        />
      )}

      <StyledModal
        open={submissionModalOpen}
        title="Add an Alternative"
        headerImage={<StyledImg fluid={submissionModalImage.childImageSharp.fluid} />}
        headerBackgroundColor="rgba(156, 208, 185, 0.104)"
        onClose={toggleSubmissionModal}
      >
        <ModalIntroText>
          Share your favorite
          {' '}
          <Link
            to={paths.faq}
            target="_blank"
            rel="noopener noreferrer"
          >
            ethical
          </Link>
          {' '}
          alternative for a popular brand, product or service.
          It can be a different brand or product, or even a practice guide
          on how to do something differently.
        </ModalIntroText>
        <SubmissionTypeToggle>
          <StyledButton onClick={handleBrandClick} className={classnames({ active: submissionType === 'brand' })}>Brand</StyledButton>
          <StyledButton onClick={handleGuideClick} className={classnames({ active: submissionType === 'guide' })}>Guide</StyledButton>
        </SubmissionTypeToggle>
        {submissionType && (
          <ItemSubmissionForm submitLabel="Submit Alternative" files={submissionFiles}>
            {submissionType === 'brand' && brandFormFieldsMarkup}
            {submissionType === 'guide' && guideFormFieldsMarkup}
            <FileUploadField
              accept="image/*"
              label="Images"
              labelHidden
              helpText="Add images"
              maxFiles={5} // limitation by Formspree
              onChange={handleSubmissionFileChange}
            />
          </ItemSubmissionForm>
        )}
      </StyledModal>
    </Layout>
  );
};

export default Alternatives;

export const AlternativesAndGuidesFragment = graphql`
  fragment AlternativesAndGuides on wordpress__wp_alternatives_and_guidesConnection {
    edges {
      node {
        id
        title
        path
        excerpt
        type
        featuredImage: featured_media {
          alt: alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 308) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags: alternative_tags {
          path
          name
        }
        categories: alternative_categories {
          path
          name
        }
        customMeta: et_custom_meta {
          commentCount: comment_count
        }
      }
    }
  }
`;
