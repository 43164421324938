/* eslint-disable react/require-default-props */
// @flow
import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import uniqueId from 'lodash.uniqueid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

import ScreenReaderText from './ScreenReaderText';
import { TextButton } from '../styles';

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];

const Wrapper = styled.div`
  margin-top: 2.1em;
`;

const Label = styled.label`
  margin-bottom: 0.9em;
`;

const FileGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -6px;
`;

const CancelButton = styled(TextButton)`
  && { /* increases specificity */
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 6px 8px;
    color: var(--color-text-negative);
    opacity: 0;
    transition: all 0.3s;
  }
`;

const FilePreview = styled.div`
  --size: 68px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin: 6px;
  font-size: 38px;
  background: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;

  &:focus,
  &:hover {
    ${CancelButton} {
      opacity: 1;
    }
  }

  img {
    display: block;
    width: var(--size);
    height: var(--size);
    object-fit: cover;
  }
`;

const AddFileButton = styled(FilePreview)`
  font-size: var(--font-size-body);
  border: 0;
`;

const HelpText = styled.p`
  margin-top: 0.9em;
`;

type Props = {
  label: string,
  name?: string,
  id?: string,
  accept?: string,
  helpText?: string,
  maxFiles?: number,
  className?: string,
  disabled?: boolean,
  required?: boolean,
  readOnly?: boolean,
  labelHidden?: boolean,
  focused?: boolean,
  onChange?: (files: File[]) => void,
  onFocus?: () => void,
  onBlur?: () => void,
};

const FileUploadField = (props: Props) => {
  const {
    label,
    name,
    id: idProp,
    accept,
    helpText,
    maxFiles = Number.MAX_VALUE,
    className,
    disabled,
    required,
    readOnly,
    labelHidden,
    focused,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const updateFiles = useCallback((newFiles: File[]) => {
    const newFilesWithLimit = newFiles.slice(0, maxFiles);
    setFiles(newFilesWithLimit);

    if (onChange && typeof onChange === 'function') {
      onChange(newFilesWithLimit);
    }
  }, [onChange]);

  const handleChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    const newFiles = [...files, ...event.currentTarget.files];
    updateFiles(newFiles);
  }, [files, updateFiles]);

  const handleAddClick = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleFileCancel = useCallback((file: File) => {
    const newFiles = files.filter((f) => f !== file);
    updateFiles(newFiles);
  }, [files, updateFiles]);

  const id = useMemo(() => idProp || uniqueId('FileUpload'), [idProp]);
  const helpTextId = `${id}HelpText`;

  const labelTextMarkup = labelHidden && label
    ? <ScreenReaderText>{label}</ScreenReaderText>
    : label;

  const uploadedFilesMarkup = files && files.map((file: File) => {
    const thumbnailMarkup = validImageTypes.includes(file.type)
      ? <img src={window.URL.createObjectURL(file)} alt="file preview" />
      : <FontAwesomeIcon icon={faFile} />;

    return (
      <FilePreview title={file.name} key={file.name}>
        {thumbnailMarkup}
        <CancelButton onClick={() => handleFileCancel(file)}>
          <FontAwesomeIcon icon={faTimes} />
        </CancelButton>
      </FilePreview>
    );
  });

  return (
    <Wrapper className={className}>
      <Label htmlFor={id}>{labelTextMarkup}</Label>
      <FileGrid>
        {uploadedFilesMarkup}
        {files.length < maxFiles && (
          <AddFileButton key="file-add-button" as={TextButton} onClick={handleAddClick}>
            <FontAwesomeIcon icon={faPlus} />
          </AddFileButton>
        )}
      </FileGrid>
      <ScreenReaderText>
        <input
          ref={inputRef}
          style={{ visibility: 'hidden' }}
          name={name}
          id={id}
          type="file"
          multiple
          accept={accept}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          focused={focused}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          aria-describedby={helpText ? helpTextId : undefined}
        />
      </ScreenReaderText>
      {helpText && <HelpText id={helpTextId}>{helpText}</HelpText>}
    </Wrapper>
  );
};

export default FileUploadField;
