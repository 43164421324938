// @flow
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from '@reach/router';

import { graphql, useStaticQuery } from 'gatsby';
import { useCallback, useState, useEffect } from 'react';

function useSubmissionModal() {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "illustration-submissions-modal.png" }) {
        childImageSharp {
          fluid(maxWidth: 582) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (window.location.hash === '#add-item-modal') {
      setOpen(true);
    }
  }, []);

  const toggle = useCallback(() => {
    setOpen(!open);
    setFiles([]);
    if (window.location.hash) {
      navigate(window.location.pathname); // remove modal hash
    }
  }, [open]);

  const fileChange = useCallback((newFiles: File[]) => {
    setFiles(newFiles);
  }, []);

  return [open, files, image, toggle, fileChange];
}

export default useSubmissionModal;
