// @flow
import React from 'react';
import styled from 'styled-components';

import { IntroText, PageHeader, AddButton } from '../styles';
import Link from './Link';

const StyledAddButton = styled(AddButton)`
  margin-left: 24px;
  margin-bottom: 0.5rem;
`;

type Props = {
  title: string,
  introText: string,
  className?: string,
  addBtnTextSuffix: string,
  addBtnUrl?: string,
  onAddBtnClick?: () => void,
};

const defaultProps = {
  className: undefined,
  addBtnUrl: '#',
  onAddBtnClick: undefined,
};

const PageHeaderWithAddButton = ({
  title,
  introText,
  className,
  addBtnTextSuffix,
  addBtnUrl,
  onAddBtnClick,
}: Props) => {
  const addButtonContent = (
    <>
      Add
      <span className="d-none d-sm-inline">
        {` ${addBtnTextSuffix}`}
      </span>
    </>
  );

  const addButtonMarkup = onAddBtnClick && typeof onAddBtnClick === 'function'
    ? <StyledAddButton onClick={onAddBtnClick}>{addButtonContent}</StyledAddButton>
    : <StyledAddButton as={Link} to={addBtnUrl || ''}>{addButtonContent}</StyledAddButton>;

  return (
    <PageHeader className={className}>
      <div className="d-flex align-items-center justify-content-lg-between">
        {/* eslint-disable-next-line react/no-danger */}
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {addButtonMarkup}
      </div>
      <div className="row">
        <div className="col-md-9 col-lg-7">
          <IntroText dangerouslySetInnerHTML={{ __html: introText }} />
        </div>
      </div>
    </PageHeader>
  );
};

PageHeaderWithAddButton.defaultProps = defaultProps;

export default PageHeaderWithAddButton;
