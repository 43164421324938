// @flow
import * as React from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import { TextButton } from '../styles';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Toggle = styled(TextButton)`
  font-size: 13px;

  &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 7px solid #4a90e2;
    transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'none')};
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  @media (min-width: 768px) {
    font-size: 16px;

    &::before {
      margin-right: 15px;
    }
  }
`;

type Props = {
  title: string,
  className?: string,
  children: React.Node,
};

const defaultProps = {
  className: '',
};

const Collapse = ({ title, className, children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Wrapper className={className}>
      <Toggle isOpen={isOpen} onClick={toggle}>
        {title}
      </Toggle>
      <AnimateHeight
        duration={500}
        animateOpacity
        height={isOpen ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
    </Wrapper>
  );
};

Collapse.defaultProps = defaultProps;

export default Collapse;
