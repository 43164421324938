// @flow
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';
import { Button } from '../styles';
import TextStyle from './TextStyle';

const Form = styled.form`
  margin-top: 33px;

  > div {
    margin-top: 2.1em;
  }

  input {
    width: 100%;
    max-width: 368px;
  }

  textarea {
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-top: 54px;
  }
`;

const SubmitButtonIcons = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SubmitButtonText = styled.span``;

const SubmitButton = styled(Button)`
  position: relative;
  margin-top: 44px;

  &:disabled {
    opacity: 1;
  }

  &.submitting {
    color: var(--color-text-primary);
    border-color: var(--color-text-primary);

    ${SubmitButtonText} {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.success {
    color: var(--color-text-positive);
    border-color: var(--color-text-positive);

    ${SubmitButtonText} {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

type Props = {
  submitLabel: string,
  files: File[],
  children: React.Node,
}

const ItemSubmissionForm = (props: Props) => {
  const {
    submitLabel,
    files,
    children,
  } = props;
  const [submitting, setSubmitting] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [formResetKey, setFormResetKey] = React.useState(1);

  const handleSubmit = React.useCallback(async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);
    setSuccess(false);
    setError(false);

    const data = new FormData(event.currentTarget);

    files.forEach((file) => {
      data.append('Images[]', file);
    });

    try {
      const response = await fetch(config.itemSubmissionFormAction, {
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccess(true);
        setFormResetKey(formResetKey + 1);
      } else {
        throw new Error(response.statusText);
      }
    } catch (e) {
      setError(true);
    }

    setSubmitting(false);
  }, [files, formResetKey]);

  // make success state reset automatically after 3 seconds
  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
    return undefined; // to fix flow error
  }, [success]);

  return (
    <Form key={formResetKey} action={config.itemSubmissionFormAction} method="post" onSubmit={handleSubmit}>
      <input type="text" name="_gotcha" style={{ display: 'none' }} />
      {children}
      {error && <TextStyle as="p" variation="negative">Oops! Something went wrong. Please try again.</TextStyle>}
      <SubmitButton type="submit" className={classnames({ submitting, success })} disabled={submitting || success}>
        <SubmitButtonIcons>
          {success && <FontAwesomeIcon icon={faCheck} />}
          {submitting && <FontAwesomeIcon icon={faSpinner} pulse />}
        </SubmitButtonIcons>
        <SubmitButtonText>{submitLabel}</SubmitButtonText>
      </SubmitButton>
    </Form>
  );
};

export default ItemSubmissionForm;
