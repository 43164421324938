// @flow
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Portal } from 'react-portal';

import { TextButton } from '../styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInAndScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999999;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.39);
  z-index: -1;
  animation: ${fadeIn} 0.3s;
`;

const Container = styled.div`
  background: #fff;
  max-width: 640px;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
  animation: ${fadeInFromTop} 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);

  @media (min-width: 768px) {
    margin: 20px;
    max-height: calc(100vh - 40px);
    border-radius: 4px;
    animation-name: ${fadeInAndScale};
    animation-duration: 0.3s;
  }
`;

const Header = styled.div`
  --background-height: 140px;

  position: relative;
  min-height: var(--background-height);

  .gatsby-image-wrapper {
    margin: 0 auto; /* horizontally center gatsby image */
  }

  @media (min-width: 768px) {
    --background-height: 188px;
  }
`;

const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--background-height);
  background: #ffdbea;
`;

const CloseButton = styled(TextButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 14px;
  font-size: 22px;
  z-index: 1;

  @media (min-width: 768px) {
    font-size: 32px;
    padding: 10px 15px;
  }
`;

const Body = styled.div`
  padding: 40px 20px;

  @media (min-width: 768px) {
    padding: 42px 36px;
  }
`;

type Props = {
  open: boolean,
  title: string,
  headerImage?: React.Node,
  headerBackgroundColor?: string,
  className?: string,
  children: React.Node,
  onClose?: () => void,
};

const defaultProps = {
  headerImage: null,
  headerBackgroundColor: undefined,
  className: undefined,
  onClose: undefined,
};

const Modal = ({
  open,
  title,
  headerImage,
  headerBackgroundColor,
  className,
  children,
  onClose,
}: Props) => {
  if (!open) {
    return null;
  }

  const handleClose = React.useCallback(() => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }, []);

  const headerBackgroundStyles = headerBackgroundColor
    ? { backgroundColor: headerBackgroundColor }
    : undefined;

  return (
    <Portal>
      <Wrapper className={className} role="dialog">
        <Overlay onClick={handleClose} />
        <Container>
          {headerImage && (
            <Header>
              <HeaderBackground style={headerBackgroundStyles} />
              <CloseButton onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </CloseButton>
              {headerImage}
            </Header>
          )}
          <Body role="document">
            <h3 className="h1">{title}</h3>
            {children}
          </Body>
        </Container>
      </Wrapper>
    </Portal>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
