/**
 * Custom styled react-select component
 *
 * @flow
 */
import React, { useCallback, useState, useMemo } from 'react';
import type { ValueType, ActionMeta } from 'react-select/src/types';
import ReactSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';
import classnames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import styled from 'styled-components';

import { Wrapper, Label } from './TextField';

const StyledLabel = styled(Label)``;
const StyledWrapper = styled(Wrapper)`
  display: block;

  ${StyledLabel} {
    top: 50%;
    transform: translateY(-50%);
  }

  &.SelectField--isFocused,
  &.SelectField--hasValue {
    ${StyledLabel} {
      transform: translateY(-180%);
    }
  }
`;

const reactSelectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    marginTop: '18px',
    backgroundColor: 'white',
    border: '0 !important',
    borderBottom: '2px solid !important',
    borderBottomColor: isFocused
      ? 'var(--color-border-input-focus) !important'
      : 'var(--color-border) !important',
    outline: '0 !important',
    boxShadow: 'none !important',
    borderRadius: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9,
    borderRadius: '4px',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? 'var(--color-secondary)' : undefined,
    color: isFocused ? '#fff' : undefined,
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#efefef',
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? 'var(--color-text-positive)' : undefined,
  }),
  placeholder: (styles) => ({
    ...styles,
    display: 'none',
  }),
};

type Props = {
  label: string,
  id: string,
  allowCreate: boolean,
  className?: string,
  onChange: (ValueType, ActionMeta) => void,
  onFocus: (event: SyntheticEvent<HTMLElement>) => void,
  onBlur: (event: SyntheticEvent<HTMLElement>) => void,
};

const defaultProps = {
  className: undefined,
};

const Select = ({
  label,
  id: idProp,
  allowCreate,
  className,
  onChange,
  onFocus,
  onBlur,
  ...restProps
}: Props) => {
  const [hasValue, setHasValue] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const id = useMemo(() => idProp || uniqueId('TextField'), [idProp]);

  const handleChange = useCallback((value, ...restArgs) => {
    const arrayValue = value || [];
    setHasValue(arrayValue.length > 0);

    if (onChange && typeof onChange === 'function') {
      onChange(value, ...restArgs);
    }
  });

  const handleFocus = useCallback((event: SyntheticEvent<HTMLElement>) => {
    setIsFocused(true);
    if (onFocus && typeof onFocus === 'function') {
      onFocus(event);
    }
  });

  const handleBlur = useCallback((event: SyntheticEvent<HTMLElement>) => {
    setIsFocused(false);
    if (onBlur && typeof onBlur === 'function') {
      onBlur(event);
    }
  });

  const ReactSelectComponent = allowCreate ? CreatableReactSelect : ReactSelect;

  return (
    <StyledWrapper
      className={classnames(className, {
        'SelectField--hasValue': hasValue,
        'SelectField--isFocused': isFocused,
      })}
    >
      <StyledLabel>{label}</StyledLabel>
      <ReactSelectComponent
        inputId={id}
        styles={reactSelectStyles}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        // Allowing prop spreading as this component works as a wrapper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      />
    </StyledWrapper>
  );
};

Select.defaultProps = defaultProps;

export default Select;
